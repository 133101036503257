.grid-multi-select-wrapper {
  width: 450px !important;
}

.green-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--green-100);
}
.green-tooltip.p-tooltip .p-tooltip-text {
  background: var(--green-100);
  color: #495057;
}

.status-success{
  background-color: #88F42B;
  color: #495057;
  border: none !important;
}
.status-danger{
  background-color:#F78B22 ;
  color: #495057;
  border: none !important;
}
.status-warning{
  background-color:#F8C726 ;
  color: #495057;
  border: none !important;
}

/* background: #544F5A;
background: #F0F1F7;
  */
