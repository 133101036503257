.login {
  box-sizing: border-box;
  display: flex;
 
  width: 100%;

}
.left_login{
  flex-basis: 70%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-width: 70%;
  background: red;
}
.loginpan {
  flex-basis: 30%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-width: 30%;
}
.loginlogo {
  width: 100%;
  text-align: center;
  margin: 90px 0 0 0;

}
.loginlogo img {
  margin-right: 35px;
  max-width: 160px;
}
.loginlogo p {
  color: #93a4b7;
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  width: 100%;
  text-align: left;
}
.login .formrow {

  padding: 28px 50px;
  margin: 30px auto;
  width: 440px;
text-align: left;
}
.login .formrow .form-control {
  background: none;
  border: 1px solid #6a7a8c59;
  width: 100%;
  height: 40px;
  padding: 0 10px 0 50px;
  margin-bottom: 10px;
  border-radius: 0px;
}

.forget {
  padding-top: 30px;
}
.forget p {
  font-size: 14px;
  color: #000;
  line-height: 35px;
}

.forget p a {
  text-decoration: none;
  color: #f78b22;
  border-bottom: 1px solid #f78b22;
}

.login .formrow .btn-primary {
  width: 100%;
  background-image: linear-gradient(to bottom, #f78b22, #f78b22);
  padding: 8px 0;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 12px;
  color: #fff;
  border: 0px;
}
.btn-primary:active,
.btn-primary:focus {
  outline: 0px;
  box-shadow: 0 0 0 0 rgb(255 255 255 / 50%);
  border: 0px;
  background: inherit;
}
.formrow h2 {
  text-align: left;
  font-size: 25px;
  margin-bottom: 8px;
  letter-spacing: 0px;
  color: #1c293b;
  /* text-transform: uppercase; */
}
.formrow p {
 margin-bottom: 20px;
}
.login .formrow .btn-primary:disabled {
  opacity: 0.15;
}
.login .formrow .user_icon {
  position: relative;
}
.login .formrow .user_icon::after {
  background: url(../images/envelope.svg);
  background-repeat: no-repeat;
  background-position: 10px center;
  content: '';
  position: absolute;
  width: 44px;
  height: 30px;
  left: 0;
  top: 38px;
}
.login .formrow .pass_icon {
  position: relative;
}

.login .formrow .pass_icon::after {
  background: url(../images/lock.svg);
  background-repeat: no-repeat;
  background-position: 10px center;
  content: '';
  position: absolute;
  width: 44px;
  height: 30px;
  left: 0;
  top: 38px;
}
