.banner {
  display: flex;
  color: white;
  align-items: center;
  background-color: #dc3545;
  text-align: center;
  font-weight: 600;
}

.banner-item {
  flex: 1
}