.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.opacity70 {
  opacity: 0.7;
}

.not-found-text {
  text-align: center;
  width: 100%;
  padding: 20px 0;
  font-weight: 500;
}
