.p-float-label label {
    font-size: 13.5px;
}

.field-checkbox {
    font-size: 12.5px;
}

.p-checkbox .p-checkbox-box{
    border-radius: 50%;
}