.custom-disabled-input {
  pointer-events: none;
  background-color: #ffffff !important;
  color: #495057 !important;
  border: 1px solid #ced4da !important; 
  border-radius: 3px; 
  opacity: 1 !important; 
  cursor: default; 
  cursor: text !important;
}

